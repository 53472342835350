import { Row, Col, Container, Navbar, Nav, NavDropdown, Image } from "react-bootstrap";
import { ConnectButtonCustom } from "../components/ConnectButtonCustom";
import { useContractWrite } from "wagmi";
import { useEffect, useState } from "react";

import Logo from "../assets/3D_LOGO_NOTEXT.png";
import Telegram from "../assets/telegram.png";
import Twitter from "../assets/twitter.png";
import Token0 from "../assets/TOKENS_0.png";
import Token1 from "../assets/TOKENS_1.png";
import Token2 from "../assets/TOKENS_2.png";
import BaseImage from '../assets/partners/base.svg';
import ScrollImage from '../assets/partners/scroll.svg';
import LayerZeroImage from '../assets/partners/layer-zero.svg';
import ArbitrumImage from '../assets/partners/arbitrum.svg';
import PepeImage from '../assets/partners/pepe.svg';

import One from "../assets/one.png";
import Two from "../assets/two.png";
import Three from "../assets/three.png";
const RevenueShareABI = require("../assets/EspressoRevenueShare.json");

const BottomBanner = () => {
  return (
    <div
      style={{
        // position: "fixed",
        // bottom: 0,
        // left: 0,
        width: "100%",
        backgroundColor: "#000", // Set your desired background color
        color: "white", // Set your desired text color
        textAlign: "center",
        padding: "10px 0", // Adjust padding as needed
        zIndex: 1000, // Ensure it sits above other content
      }}
    >
      Please note that our services are not available to users in the United
      States or individuals in countries subject to embargo restrictions.
    </div>
  );
};

function Home() {
  let isMobile = window.innerWidth < 600;
  const { error, isError, isSuccess } = useContractWrite({
    address: "0xc5BaE4C954BF76F7540A64f5C0dA9144dC0517AF",
    abi: RevenueShareABI,
    functionName: "redeem",
  });

  useEffect(() => {
    if (isError) {
      alert(error);
    }
    if (isSuccess) {
      alert("Redeemed!");
    }
    // eslint-disable-next-line
  }, [isError, isSuccess]);

  const [language, setLanguage] = useState("english");
  async function fetchUserLocation() {
    try {
      const response = await fetch("https://api.ipify.org?format=json");
      const { ip } = await response.json();

      const locationResponse = await fetch(`https://ipapi.co/${ip}/json/`);
      const locationData = await locationResponse.json();
      setLanguage(locationData.continent_code === "CN" ? "chinese" : "english");
    } catch (error) {
      console.error("Error fetching user location:", error);
    }
  }

  useEffect(() => {
    fetchUserLocation();
  }, []);

  return (
    <Container fluid>
      <Navbar
        variant="dark"
        sticky="top"
        expand="lg"
        style={{ backgroundColor: "black" }}
      >
        <Container fluid>
          <Navbar.Brand style={{ display: "flex", alignItems: "center" }}>
            <img height={30} src={Logo} alt="Logo" />
            <span id="logotext">ESPRESSO</span>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbar-nav" />
          <Navbar.Collapse id="navbar-nav" className="justify-content-end">
            <Nav className="ml-auto">
              <Nav.Link
                onClick={() =>
                  window.open(
                    language === "english"
                      ? "https://espressobot.gitbook.io/espressobot-docs/"
                      : "https://espressobot.gitbook.io/espressobot-docs-chinese/",
                    "_blank"
                  )
                }
                className="text-center"
              >
                {language === "english" ? "Docs" : "文档"}
              </Nav.Link>
              <Nav.Link
                onClick={() =>
                  window.open("https://t.me/espresso_deployer_bot", "_blank")
                }
                className="text-center"
              >
                {language === "english" ? "Espresso Bot" : "机器人"}
              </Nav.Link>
              <Nav.Link
                onClick={() =>
                  window.open("https://twitter.com/espressoTG", "_blank")
                }
                className="text-center"
              >
                {language === "english" ? "Twitter" : "推特"}
              </Nav.Link>
              <Nav.Link
                onClick={() =>
                  window.open("https://t.me/+ZRX_BM3wUGwwODM0", "_blank")
                }
                className={`text-center`}
              >
                {language === "english" ? "Telegram" : "Telegram"}
              </Nav.Link>
              <NavDropdown
                title={language === "english" ? "English" : "中文"}
                className="text-center"
              >
                <NavDropdown.Item onClick={() => setLanguage("english")}>
                  English
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => setLanguage("chinese")}>
                  中文
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link className="text-center">
                <ConnectButtonCustom showBalance={false} language={language} />
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container
        fluid
        id="bannercontainer"
        style={{ height: isMobile ? "100%" : "100vh" }}
      >
        <Container
          fluid
          id="bannercontainer"
          style={{ height: isMobile ? "80vh" : "" }}
        >
          <Row
            style={{ height: "100%" }}
            className={`justify-content-center align-items-center ${
              isMobile ? "text-center" : ""
            }`}
          >
            <Col xs={12} lg={4} className="text-center">
              <img
                width="85%"
                src={Logo}
                alt="Logo"
                className={`${isMobile ? "mb-5" : ""}`}
              />
            </Col>
            <Col xs={12} lg={6}>
              <div>
                {language === "english" ? (
                  <span className="bannertext" style={{ lineHeight: "70%" }}>
                    LAUNCH <br />
                    YOUR TOKEN <br />
                    IN <span style={{ color: "#FF2FBE" }}>MINUTES</span> <br />
                    WITH ESPRESSO
                  </span>
                ) : (
                  <span
                    className="bannertext"
                    style={{ fontSize: isMobile ? "3.2en" : "4" }}
                  >
                    发行您的代币
                    <br />在<span style={{ color: "#FF2FBE" }}>
                      几分钟内
                    </span>{" "}
                    <br />
                    使用 Espresso
                    <br />
                  </span>
                )}
              </div>
              <div className="mt-2">
                <span className="bannersubtext">
                  {language === "english"
                    ? "An Intuitive Telegram Bot for instant Token Deployment"
                    : "一个直观的 Telegram 机器人，可立即部署代币"}
                </span>
              </div>
            </Col>
          </Row>
        </Container>

        <Container fluid id="socialcontainer" className="mt-5">
          <Row
            style={{ height: "100%" }}
            className="justify-content-center align-items-center"
          >
            <Col
              xs={12}
              lg={2}
              className="text-center"
              style={{ marginBottom: isMobile ? 60 : 0 }}
              onClick={() =>
                window.open("https://t.me/+ZRX_BM3wUGwwODM0", "_blank")
              }
            >
              <img
                width="40%"
                src={Telegram}
                alt="Logo"
                style={{ cursor: "pointer" }}
              />
              <h2 className="mt-5">
                <b>{language === "english" ? "Telegram" : "Telegram"}</b>
              </h2>
            </Col>
            <Col
              xs={12}
              lg={2}
              className="text-center"
              style={{ cursor: "pointer" }}
              onClick={() =>
                window.open("https://t.me/+ZRX_BM3wUGwwODM0", "_blank")
              }
            >
              <img width="40%" src={Twitter} alt="Logo" />
              <h2 className="mt-5">
                <b>{language === "english" ? "Twitter" : "推特"}</b>
              </h2>
            </Col>
          </Row>
        </Container>
      </Container>
{/* 
      <Container>
        <Row className="justify-content-center align-items-center">
          <Col
            xs={12}
            md={6}
            className="text-center mb-5 pb-5"
            style={{ marginBottom: 50 }}
            onClick={() => write()}
          >
            <div className="pb-3 redeembutton" style={{ paddingTop: 20 }}>
              {language === "english" ? "Redeem Rewards" : "兑换奖励"}
            </div>
          </Col>
        </Row>
      </Container> */}

      <Container fluid className="mt-5">
        <Row className="justify-content-center align-items-center">
          <Col xs={12} className="text-center">
            {language === "english" ? (
              <span className="bannerpink">
                STRUGGLING WITH <br />
                COMPLEX CODING <br />
                AND CONTRACTS?
              </span>
            ) : (
              <span className="bannerpink">
                为复杂的编码和合约而苦恼？
                <br />
                难以应付？
              </span>
            )}
          </Col>
          <Col className="text-center my-5 py-5">
            <img height={120} src={Logo} alt="Logo" />
          </Col>
          <Col xs={12} className="text-center mb-5">
            {language === "english" ? (
              <span className="bannertext">
                ESPRESSO <br />
                MAKES IT <br />
                <span style={{ color: "#FF2FBE" }}>EASY</span> <br />
                FOR YOU!
              </span>
            ) : (
              <span className="bannertext">
                ESPRESSO
                <br />
                使它对您来说变得
                <br />
                <span style={{ color: "#FF2FBE" }}>简单</span>！<br />
              </span>
            )}
          </Col>
          <Col xs={8} className="text-center my-5">
            <div className="divider-gradient"></div>
          </Col>
        </Row>
      </Container>

      {/* <Container className="mt-5">
        <Row className="justify-content-center align-items-center mt-5">
          <Col xs={12} md={4} className="text-center mt-3">
            {language === "english" ? (
              <div className="bulletpoint">
                MARKET CAP.
                <br /> $2.46M
              </div>
            ) : (
              <div className="bulletpoint">
                市值
                <br /> $2.46 百万美元
              </div>
            )}
          </Col>

          <Col xs={12} md={4} className="text-center mt-3">
            {language === "english" ? (
              <div className="bulletpoint">
                {"<"} 2 MINUTES
                <br /> TO DEPLOY
              </div>
            ) : (
              <div className="bulletpoint">
                小于 2 分钟
                <br /> 完成部署
              </div>
            )}
          </Col>

          <Col xs={12} md={4} className="text-center mt-3">
            {language === "english" ? (
              <div className="bulletpoint">
                REVENUE
                <br /> DISTRIBUTED
                <br /> TO HOLDERS
              </div>
            ) : (
              <div className="bulletpoint">
                收入
                <br /> 分发
                <br /> 给持有者
              </div>
            )}
          </Col>
        </Row>
      </Container> */}

      <Container
        fluid
        className="outer-container"
        style={{
          marginTop: isMobile ? 50 : 150,
          padding: isMobile ? "auto" : "0px 100px",
        }}
      >
        <Container
          fluid
          className="fadebackground"
          style={{ padding: isMobile ? "auto" : "100px 60px" }}
        >
          <Row className="justify-content-center align-items-center">
            <Col xs={12} md={6} className="text-center mb-3">
              <div className="bulletpoint2">
                <h1 className="bullettitle">
                  {language === "english" ? "EASY AND FAST" : "简单快捷"}
                </h1>
                {language === "english" ? (
                  <p className="bulletdescription">
                    Launch your ERC-20 token <br /> within minutes
                  </p>
                ) : (
                  <p className="bulletdescription">
                    在几分钟内
                    <br />
                    发行您的 ERC-20 代币
                  </p>
                )}
              </div>
            </Col>

            <Col xs={12} md={6} className="text-center mb-3">
              <div className="bulletpoint2">
                <h1 className="bullettitle">
                  {language === "english" ? "NO CODING NEEDED" : "无需编码"}
                </h1>
                {language === "english" ? (
                  <p className="bulletdescription">
                    Our intuitive Telegram bot handles <br /> all the technical
                    aspects for you
                  </p>
                ) : (
                  <p className="bulletdescription">
                    我们直观的 Telegram 机器人为您处理
                    <br />
                    所有技术方面的事务
                  </p>
                )}
              </div>
            </Col>

            <Col xs={12} md={6} className="text-center mb-3">
              <div className="bulletpoint2">
                <h1 className="bullettitle">
                  {language === "english" ? "SAFE AND SECURE" : "安全可靠"}
                </h1>
                {language === "english" ? (
                  <p className="bulletdescription">
                    Default contract structure protects <br /> your token from
                    malicious attacks
                  </p>
                ) : (
                  <p className="bulletdescription">
                    默认合约结构保护您的代币免受恶意攻击
                  </p>
                )}
              </div>
            </Col>

            <Col xs={12} md={6} className="text-center mb-3">
              <div className="bulletpoint2">
                <h1 className="bullettitle">
                  {language === "english" ? "CUSTOMIZABLE" : "可定制"}
                </h1>
                {language === "english" ? (
                  <p className="bulletdescription">
                    Personalize your token symbol,
                    <br /> total supply, tax allocations and more
                  </p>
                ) : (
                  <p className="bulletdescription">
                    个性化您的代币符号、
                    <br />
                    总供应量、税收分配等等
                  </p>
                )}
              </div>
            </Col>
          </Row>

          <Row className="justify-content-center align-items-center">

            <Col xs={8} className="text-center my-5">
              <div className="divider-gradient"></div>
            </Col>

            <Col xs={12} className="text-center my-5">
              {language === "english" ? (
                <div className="bannerpink2 mt-5">
                  DEPLOY <span style={{ color: "#59BEFF" }}>ANY</span> KIND OF
                  TOKEN
                </div>
              ) : (
                <div className="bannerpink2 mt-5">
                  部署<span style={{ color: "#59BEFF" }}>任何</span>类型的代币
                </div>
              )}
            </Col>
          </Row>

          <Row className="py-5">
            <Col xs={12} md={4}>
              <img alt="lol" width="100%" src={Token0} />
            </Col>
            <Col xs={12} md={4}>
              <img alt="lol" width="100%" src={Token1} />
            </Col>
            <Col xs={12} md={4}>
              <img alt="lol" width="100%" src={Token2} />
            </Col>
          </Row>

          <Row className="justify-content-center align-items-center">
            <Col xs={12} className="text-center my-5">
              {language === "english" ? (
                <div className="bannerbluebigger">
                  LET YOUR <span style={{ color: "#FF2FBE" }}>IDEAS </span>
                  <br />
                  COME TO LIFE <br />
                  WITH <span style={{ color: "#FF2FBE" }}>ESPRESSO</span>
                </div>
              ) : (
                <div className="bannerbluebigger">
                  让您的<span style={{ color: "#FF2FBE" }}>创意</span>
                  <br />在<span style={{ color: "#FF2FBE" }}>ESPRESSO</span>
                  的帮助下
                  <br />
                  变为现实
                </div>
              )}
            </Col>
            <Col xs={8} className="text-center">
              <div
                style={{ marginTop: 150, marginBottom: 150 }}
                className="divider-gradient"
              ></div>
            </Col>
          </Row>

          <Container className="mt-5">
            <Row className="justify-content-center align-items-center">
              <Col xs={12} className="text-center" style={{ marginBottom: 50 }}>
                <div
                  className="howitworks pb-3"
                  style={{ paddingTop: language === "chinese" ? 20 : "auto" }}
                >
                  {language === "english" ? "HOW IT WORKS" : "工作原理"}
                </div>
              </Col>
            </Row>
          </Container>

          <Container fluid className="mt-5">
            <Row
              className="justify-content-center align-items-center"
              style={{ marginBottom: 120 }}
            >
              <Col xs={12} md={2} className="text-center">
                <img alt="lol" width={isMobile ? "40%" : "60%"} src={One} />
              </Col>
              <Col
                xs={12}
                md={10}
                style={{ height: "100%" }}
                className={`text-start d-flex flex-column justify-content-center ${
                  isMobile ? "text-center mt-4" : ""
                }`}
              >
                {language === "english" ? (
                  <>
                    <h1 class="worktitle">CONNECT TO TELEGRAM BOT</h1>
                    <p class="workdescription">
                      Access EspressoBot and begin the journey.
                    </p>
                  </>
                ) : (
                  <>
                    <h1 class="worktitle">连接到Telegram机器人</h1>
                    <p class="workdescription">
                      访问 EspressoBot 并开始您的旅程。
                    </p>
                  </>
                )}
              </Col>
            </Row>

            <Row
              className="justify-content-center align-items-center"
              style={{ marginBottom: 120 }}
            >
              <Col xs={12} md={2} className="text-center">
                <img alt="lol" width={isMobile ? "40%" : "60%"} src={Two} />
              </Col>
              <Col
                xs={12}
                md={10}
                className={`text-start d-flex flex-column justify-content-center ${
                  isMobile ? "text-center mt-4" : ""
                }`}
              >
                {language === "english" ? (
                  <>
                    <h1 class="worktitle">CHOOSE TOKEN DETAILS</h1>
                    <p class="workdescription">
                      Customize your token with ease.
                    </p>
                  </>
                ) : (
                  <>
                    <h1 class="worktitle">选择代币详情</h1>
                    <p class="workdescription">轻松定制您的代币。</p>
                  </>
                )}
              </Col>
            </Row>

            <Row
              className="justify-content-center align-items-center"
              style={{ marginBottom: 120 }}
            >
              <Col xs={12} md={2} className="text-center">
                <img alt="lol" width={isMobile ? "40%" : "60%"} src={Three} />
              </Col>
              <Col
                xs={12}
                md={10}
                className={`text-start d-flex flex-column justify-content-center ${
                  isMobile ? "text-center mt-4" : ""
                }`}
              >
                {language === "english" ? (
                  <>
                    <h1 class="worktitle">DEPLOY YOUR TOKEN</h1>
                    <p class="workdescription">
                      Launch your token on Ethereum or supported networks
                      instantly.
                    </p>
                  </>
                ) : (
                  <>
                    <h1 class="worktitle">部署您的代币</h1>
                    <p class="workdescription">
                      即刻在以太坊或支持的网络上启动您的代币。
                    </p>
                  </>
                )}
              </Col>
            </Row>
          </Container>
        </Container>
      </Container>

      <Container>
        {/* <Row style={{ marginBottom: "100px" }}>
          <Col xs={12} className="text-center">
            {language === "english" ? (
              <>
                <span className="bannerbluebigger">
                  <span style={{ color: "#FF2FBE" }}>
                    REVENUE SHARE {isMobile ? <br /> : ""}{" "}
                  </span>
                  FROM <br />
                  <span>BOT REVENUES AND</span>
                  <br />
                  <span>$ESPR TRADING VOLUME</span>
                </span>
              </>
            ) : (
              <>
                <span className="bannerbluebigger">
                  <span style={{ color: "#FF2FBE" }}>
                    收入分成 {isMobile ? <br /> : ""}
                  </span>
                  来自于
                  <br />
                  机器人收入和
                  <br />
                  $ESPR 交易量
                </span>
              </>
            )}
          </Col>
        </Row> */}

        {/* <Row
          className="justify-content-center align-items-center"
          style={{ marginBottom: 280 }}
        >
          <Col xs={9} className="text-center my-4">
            <span className="bullet2">
              {language === "english"
                ? "2.2K $ESPR HOLDERS"
                : "2.2千名$ESPR持有者"}
            </span>
          </Col>

          <Col xs={9} className="text-center my-4">
            <div
              className="buybutton2"
              onClick={() =>
                window.open(
                  "https://app.uniswap.org/#/tokens/ethereum/0xa3c31927a092bd54eb9a0b5dfe01d9db5028bd4f",
                  "_blank"
                )
              }
            >
              {language === "english" ? "BUY $ESPR" : "购买$ESPR"}
            </div>
          </Col>
        </Row> */}

        <section style={{ marginBottom: 200 }}>
        <Row
          className="justify-content-center align-items-center"          
        >
          <Col sm={12} className="text-center">
            {language === "english" ? (
              <>
                <span className="bannerbluebigger">
                  <span style={{ color: "#FF2FBE" }}>
                    PARTNERS {isMobile ? <br /> : ""}{" "}
                  </span>
                </span>
              </>
            ) : (
              <>
                <span className="bannerbluebigger">
                  <span style={{ color: "#FF2FBE" }}>
                  伙伴 {isMobile ? <br /> : ""}
                  </span>                  
                </span>
              </>
            )}
          </Col> 
        </Row>
        <Row className="justify-content-center mt-5">
          <Col sm={12} md={4} className="mb-5 text-center"><Image src={BaseImage} height="50"/></Col>
          <Col sm={12} md={4} className="mb-5 text-center"><Image src={ScrollImage} height="50"/></Col>
          <Col sm={12} md={4} className="mb-5 text-center"><Image src={LayerZeroImage} height="50"/></Col>
          <Col sm={12} md={4} className="mb-5 text-center"><Image src={ArbitrumImage} height="50"/></Col>
          <Col sm={12} md={4} className="mb-5 text-center"><Image src={PepeImage} height="50"/></Col>
        </Row>
        </section>

        <Row className="justify-content-center align-items-center">
          <Col xs={8} className="text-center my-5">
            <div className="divider-gradient"></div>
          </Col>
        </Row>
      </Container>

      <Container
        fluid
        style={{
          paddingLeft: isMobile ? 0 : 200,
          paddingRight: isMobile ? 0 : 200,
        }}
      >
        <Row className="justify-content-center">
          <Col xs={12} md={3} className="text-center">
            <h1 className="footertitle">
              <b>{language === "english" ? "Links" : "链接"}</b>
            </h1>
            <p
              className="footerelem"
              style={{ cursor: "pointer" }}
              onClick={() =>
                window.open("https://t.me/espresso_deployer_bot", "_blank")
              }
            >
              {language === "english" ? "Telegram Bot" : "Telegram 机器人"}
            </p>
          </Col>
          <Col xs={12} md={3} className="text-center">
            <h1 className="footertitle">
              <b>{language === "english" ? "Socials" : "社交媒体"}</b>
            </h1>
            <p
              className="footerelem"
              style={{ cursor: "pointer" }}
              onClick={() =>
                window.open("https://twitter.com/espressoTG", "_blank")
              }
            >
              {language === "english" ? "Twitter" : "推特"}
            </p>
            <p
              className="footerelem"
              style={{ cursor: "pointer" }}
              onClick={() =>
                window.open("https://t.me/+ZRX_BM3wUGwwODM0", "_blank")
              }
            >
              {language === "english" ? "Telegram Group" : "Telegram 群组"}
            </p>
          </Col>
          <Col xs={12} md={3} className="text-center">
            <h1 className="footertitle">
              <b>{language === "english" ? "Resources" : "资源"}</b>
            </h1>
            <p
              className="footerelem"
              style={{ cursor: "pointer" }}
              onClick={() =>
                window.open(
                  language === "english"
                    ? "https://espressobot.gitbook.io/espressobot-docs/"
                    : "https://espressobot.gitbook.io/espressobot-docs-chinese/",
                  "_blank"
                )
              }
            >
              {language === "english" ? "Docs" : "文档"}
            </p>

          </Col>
          {/* <Col xs={12} md={3} className="text-center">
            <h1 className="footertitle">
              <b>{language === "english" ? "Legal" : "法律声明"}</b>
            </h1>
            <p className="footerelem">
              {language === "english" ? "Terms & Conditions" : "条款和条件"}
            </p>
          </Col> */}
        </Row>
        <Row className="justify-content-center align-items-center">
          <Col xs={8} className="text-center my-4">
            <div className="divider-gradient-blue"></div>
          </Col>
        </Row>
      </Container>

      <Container fluid className="mb-5">
        <Row className="justify-content-center align-items-center">
          <Col
            xs={12}
            md={2}
            className={isMobile ? "text-center mb-3" : "text-center ml-2 mb-3"}
          >
            <img height={50} src={Logo} alt="Logo" />
          </Col>
          {/* <Col
            xs={12}
            md={5}
            className={isMobile ? "text-center" : "text-right"}
            style={{ fontSize: 15 }}
          >
            {language === "english"
              ? "By accessing our services, you accept our Terms of Service and Privacy Policy."
              : "通过访问我们的服务，您接受我们的服务条款和隐私政策。"}
          </Col> */}
          {/* <Col
            xs={12}
            md={5}
            className={isMobile ? "text-center" : "text-left"}
            style={{ fontSize: 15 }}
          >
            {language === "english"
              ? "Copyright © 2023 EspressoBot. All rights reserved."
              : "版权所有 © 2023 EspressoBot。保留所有权利。"}
          </Col> */}
        </Row>
        <BottomBanner />
      </Container>
    </Container>
  );
}

export default Home;



function VerifyPage() {

  return (
    <p>Hello,<br/><br/>

    We control the website of our project, espressobot.xyz (http://espressobot.xyz/)<br/><br/>
    
    We request you to completely erase all the recorded archives of that website since inception because we have updated it thoroughly.</p>
  );
}

export default VerifyPage;
